<template>
  <div class="change-phone-number-view">
    <PageHeader
      class="mb-4"
      :backButtonTo="`/profile`"
    >
      Изменение номера телефона
      <template v-slot:right>
        Шаг {{step}}/3
      </template>
    </PageHeader>

    <!-- Шаг 1. Получение СМС на новый номер -->
    <div v-if="step === 1">
      <b-overlay
        :show="formRequestCode.isLoading && !formRequestCode.isSended"
        rounded="sm"
        variant="white"
        opacity="0.85"
        class="mb-5"
      >
        <b-form @submit="onSubmitFormRequestCode">
          <!-- Поле Новый номер телефона -->
          <b-form-group
            id="group-input-newPhoneNumber"
            label="Новый номер телефона *"
            label-for="input-newPhoneNumber"
            :invalid-feedback="formRequestCode.fields.newPhoneNumber.invalidFeedback"
            :state="formRequestCode.fields.newPhoneNumber.state"
          >
            <b-form-input
              id="input-newPhoneNumber"
              v-model="formRequestCode.fields.newPhoneNumber.value"
              :state="formRequestCode.fields.newPhoneNumber.state"
              :disabled="formRequestCode.isSended"
              trim
              v-mask="'+7 ### ### ## ##'"
              placeholder="+7 999 999 99 99"
              type="tel"
            />
          </b-form-group>
          <!-- /Поле Новый номер телефона -->

          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="isFormRequestCodeButtonDisabled"
          >
            Получить СМС код
          </b-button>
          <!-- Ошибка сервера -->
          <b-alert
            show
            v-if="formRequestCode.serverErrorMessage"
            variant="danger"
            class="mt-3"
          >
            {{ formRequestCode.serverErrorMessage }}
          </b-alert>
          <!-- /Ошибка сервера -->
        </b-form>
      </b-overlay>
    </div>
    <!-- /Шаг 1. Получение СМС на новый номер -->

    <!-- Шаг 2. Проверка СМС кода (пришел на новый номер) и отправка СМС кода на старый -->
    <b-overlay
      :show="formVerifyFirstCode.isLoading && !formVerifyFirstCode.isSended"
      rounded="sm"
      variant="white"
      opacity="0.85"
      class="mb-5"
      v-if="step === 2"
    >
      <b-form @submit="onSubmitFormVerifyFirstCode">
        <!-- Поле СМС код -->
        <b-form-group
          id="group-input-smsCode1"
          label="Проверка СМС кода (пришел на новый номер телефона) *"
          label-for="input-smsCode1"
          :invalid-feedback="formVerifyFirstCode.fields.smsCode.invalidFeedback"
          :state="formVerifyFirstCode.fields.smsCode.state"
        >
          <b-form-input
            id="input-smsCode1"
            v-model="formVerifyFirstCode.fields.smsCode.value"
            :state="formVerifyFirstCode.fields.smsCode.state"
            :disabled="formVerifyFirstCode.isSended"
            trim
            v-mask="'# # # # # #'"
            placeholder="• • • • • •"
          />
        </b-form-group>
        <!-- /Поле СМС код -->

        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="isFormVerifyFirstCodeButtonDisabled"
        >
          Отправить
        </b-button>
        <!-- Ошибка сервера -->
        <b-alert
          show
          v-if="formVerifyFirstCode.serverErrorMessage"
          variant="danger"
          class="mt-3"
        >
          {{ formVerifyFirstCode.serverErrorMessage }}
        </b-alert>
        <!-- /Ошибка сервера -->
      </b-form>
    </b-overlay>
    <!-- /Шаг 2. Проверка СМС кода (пришел на новый номер) и отправка СМС кода на старый -->

    <!-- Шаг 3. Проверка СМС кода (пришел на старый номер) и смена номера телефона -->
    <b-overlay
      :show="formVerifySecondCode.isLoading && !formVerifySecondCode.isSended"
      rounded="sm"
      variant="white"
      opacity="0.85"
      v-if="step === 3"
    >
      <b-form @submit="onSubmitFormVerifySecondCode">
        <!-- Поле СМС код -->
        <b-form-group
          id="group-input-smsCode2"
          label="Проверка СМС кода (пришел на старый номер телефона) *"
          label-for="input-smsCode2"
          :invalid-feedback="formVerifySecondCode.fields.smsCode.invalidFeedback"
          :state="formVerifySecondCode.fields.smsCode.state"
        >
          <b-form-input
            id="input-smsCode2"
            v-model="formVerifySecondCode.fields.smsCode.value"
            :state="formVerifySecondCode.fields.smsCode.state"
            :disabled="formVerifySecondCode.isSended"
            trim
            v-mask="'# # # # # #'"
            placeholder="• • • • • •"
          />
        </b-form-group>
        <!-- /Поле СМС код -->

        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="isFormVerifySecondCodeButtonDisabled"
        >
          Отправить
        </b-button>
        <!-- Ошибка сервера -->
        <b-alert
          show
          v-if="formVerifySecondCode.serverErrorMessage"
          variant="danger"
          class="mt-3"
        >
          {{ formVerifySecondCode.serverErrorMessage }}
        </b-alert>
        <!-- /Ошибка сервера -->
      </b-form>
    </b-overlay>
    <!-- /Шаг 3. Проверка СМС кода (пришел на старый номер) и смена номера телефона -->

  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import PageHeader from '../components/PageHeader.vue';
import {
  changePhoneNumberRequestCode,
  changePhoneNumberVerifyFirstCode,
  changePhoneNumberVerifySecondCode,
} from '../api/esia/phone_number';
import { getErrorTextFromErResp } from '../api/utils';

export default {
  name: 'ChangePhoneNumber',

  components: {
    PageHeader,
  },

  directives: {
    mask,
  },

  computed: {
    isFormRequestCodeButtonDisabled() {
      return !this.formRequestCode.fields.newPhoneNumber.value
          || this.formRequestCode.fields.newPhoneNumber.value.length < 1;
    },
    isFormVerifyFirstCodeButtonDisabled() {
      return !this.formVerifyFirstCode.fields.smsCode.value
          || this.formVerifyFirstCode.fields.smsCode.value.length < 1;
    },
    isFormVerifySecondCodeButtonDisabled() {
      return !this.formVerifySecondCode.fields.smsCode.value
          || this.formVerifySecondCode.fields.smsCode.value.length < 1;
    },
  },

  data() {
    return {
      step: 1,

      formRequestCode: {
        isLoading: false,
        isSended: false,
        serverErrorMessage: null,
        fields: {
          /* Любое поле содержит:
          - (value: str) значение;
          - (state: bool) состояние ошибки;
          - (invalidFeedback: str) текст ошибки;
          * * */
          newPhoneNumber: {
            value: null,
            state: null,
            invalidFeedback: null,
          },
        },
      },

      formVerifyFirstCode: {
        isLoading: false,
        isSended: false,
        serverErrorMessage: null,
        fields: {
          /* Любое поле содержит:
          - (value: str) значение;
          - (state: bool) состояние ошибки;
          - (invalidFeedback: str) текст ошибки;
          * * */
          smsCode: {
            value: null,
            state: null,
            invalidFeedback: null,
          },
        },
      },

      formVerifySecondCode: {
        isLoading: false,
        isSended: false,
        serverErrorMessage: null,
        fields: {
          /* Любое поле содержит:
          - (value: str) значение;
          - (state: bool) состояние ошибки;
          - (invalidFeedback: str) текст ошибки;
          * * */
          smsCode: {
            value: null,
            state: null,
            invalidFeedback: null,
          },
        },
      },
    };
  },

  methods: {
    // Шаг 1. Получение СМС на новый номер
    async onSubmitFormRequestCode(event) {
      event.preventDefault();

      // Валидация
      if (!this.formRequestCode.fields.newPhoneNumber.value) {
        this.formRequestCode.fields.newPhoneNumber.state = false;
        this.formRequestCode.fields.newPhoneNumber.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.formRequestCode.isLoading = true;
      const phoneNumber = this.formRequestCode.fields.newPhoneNumber.value.replace(/[^+\d]/g, '');
      try {
        await changePhoneNumberRequestCode(phoneNumber);
        this.formRequestCode.isLoading = false;
        this.formRequestCode.isSended = true;
        this.step += 1;
      } catch (e) {
        this.formRequestCode.isLoading = false;
        this.formRequestCode.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },

    // Шаг 2. Проверка СМС кода (пришел на новый номер телефона) и отправка СМС кода на старый
    async onSubmitFormVerifyFirstCode(event) {
      event.preventDefault();

      // Валидация
      if (!this.formVerifyFirstCode.fields.smsCode.value) {
        this.formVerifyFirstCode.fields.smsCode.state = false;
        this.formVerifyFirstCode.fields.smsCode.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.formVerifyFirstCode.isLoading = true;
      try {
        await changePhoneNumberVerifyFirstCode(this.formVerifyFirstCode.fields.smsCode.value.replace(/\s/g, ''));
        this.formVerifyFirstCode.isLoading = false;
        this.formVerifyFirstCode.isSended = true;
        this.step += 1;
      } catch (e) {
        this.formVerifyFirstCode.isLoading = false;
        this.formVerifyFirstCode.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },

    // Шаг 3. Проверка СМС кода (пришел на старый номер телефона) и смена номера телефона
    async onSubmitFormVerifySecondCode(event) {
      event.preventDefault();

      // Валидация
      if (!this.formVerifySecondCode.fields.smsCode.value) {
        this.formVerifySecondCode.fields.smsCode.state = false;
        this.formVerifySecondCode.fields.smsCode.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.formVerifySecondCode.isLoading = true;
      try {
        await changePhoneNumberVerifySecondCode(this.formVerifySecondCode.fields.smsCode.value.replace(/\s/g, ''));
        this.formVerifySecondCode.isLoading = false;
        this.formVerifySecondCode.isSended = true;
        this.step = 1;
      } catch (e) {
        this.formVerifySecondCode.isLoading = false;
        this.formVerifySecondCode.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },
  },

  async mounted() {
    this.formRequestCode.isLoading = false;
    this.formRequestCode.serverErrorMessage = null;
    this.formRequestCode.isSended = false;

    this.formVerifyFirstCode.isLoading = false;
    this.formVerifyFirstCode.serverErrorMessage = null;
    this.formRequestCode.isSended = false;

    this.formVerifySecondCode.isLoading = false;
    this.formVerifySecondCode.serverErrorMessage = null;
    this.formRequestCode.isSended = false;
  },
};
</script>

<style lang="scss">
.change-phone-number-view {
  max-width: 460px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;
}
</style>
