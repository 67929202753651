import { axiosAuthServiceApiWithCredentials } from '../index';

/* Смена номере телефона */

// Шаг 1. Получение СМС на новый номер
export async function changePhoneNumberRequestCode(phoneNumber) {
  const payload = { phoneNumber };
  const urlSegment = 'phone-number-change/request-code';
  const response = await axiosAuthServiceApiWithCredentials.post(urlSegment, payload);
  return response.data;
}

// Шаг 2. Проверка СМС кода (пришел на новый номер телефона) и отправка СМС кода на старый
export async function changePhoneNumberVerifyFirstCode(code) {
  const payload = { code };
  const urlSegment = 'phone-number-change/verify-first-code';
  const response = await axiosAuthServiceApiWithCredentials.post(urlSegment, payload);
  return response.data;
}

// Шаг 3. Проверка СМС кода (пришел на старый номер телефона) и смена номера телефона
export async function changePhoneNumberVerifySecondCode(code) {
  const payload = { code };
  const urlSegment = 'phone-number-change/verify-second-code';
  const response = await axiosAuthServiceApiWithCredentials.post(urlSegment, payload);
  return response.data;
}

/* /Смена номере телефона */
